@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
:root {
  --primary: #7879f1;
  --secondary: #e5e5a8;
  --terciary: #b5a9ff;
  --text-color: #effffe;
}
html,
body {
  background-color: var(--terciary);
  /* height: 100%; */
}
* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  user-select: none;
}
body {
  overflow: auto;
  /* overflow: hidden; */
}
a {
  text-decoration: none;
  margin: 4px;
}
.container {
  height: 100%;
  align-items: center;
}
.container-flex {
  display: flex;
  flex-direction: column;
}
.header #title {
  color: var(--text-color);
  font-size: 28pt;
  text-align: center;
  margin: auto;
  padding: 2.5rem 0;
  width: fit-content;
}
.image {
  text-align: center;
}
.image > img {
  max-height: 25vh;
  opacity: 0.5;
  transition: all 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8);
}
.image > img:hover {
  max-height: 27.5vh;
}

.card {
  display: block;
}
.card > p {
  font-size: 14pt;
  color: var(--text-color);
  text-align: center;
}
.card > p > a > em {
  text-decoration: none;
  position: relative;
  color: var(--secondary);
  cursor: pointer;
  font-style: normal;
}
.card > p > a > em::before {
  content: "";
  position: absolute;
  bottom: -2px;
  right: 0;
  width: 0;
  height: 4px;
  border-radius: 5px;
  background-color: var(--primary);
  transition: width 1s cubic-bezier(0.25, 1, 0.5, 1);
}
.card > p > a > em:hover::before {
  left: 0;
  right: auto;
  width: 100%;
}

@media (min-width: 800px) {
  #root,
  .App,
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .header {
    overflow: hidden;
  }
  .container-flex {
    flex-direction: row;
    background-color: var(--text-color);
    width: max-content;
    border-radius: 50px;
    padding: 60px;
    overflow: hidden;
    margin: auto;
    align-items: center;
  }
  .container-flex span {
    height: 16rem;
    width: 6px;
    border-radius: 50px;
    background-color: var(--primary);
    margin: 0px 50px;
  }
  .card {
    min-width: 300px;
  }
  .card > p {
    color: var(--primary);
  }
  .header #title {
    color: var(--primary);
    padding: 0;
  }
  .usuarios {
    margin-top: -2.5em;
  }
}
