header.home {
  display: flex;
  align-items: center;
}
header .card .mercado-1 {
  margin-top: 4.5rem;
}
header .card .mercado-2 {
  margin-top: 3.5rem;
}
header .card .mercado-3 {
  margin-top: 2.75rem;
}
header .card .mercado-4 {
  margin-top: 2rem;
}

.item-group > .item > img {
  width: 75px;
  height: 75px;
  border-radius: 50px;
  user-select: none;
  cursor: pointer;
  transition: all .4s cubic-bezier(.24,-0.06,.76,1.06);
}

.item-group > .item > img:hover {
    filter: opacity(80%) grayscale(1);
}

.home-container {
  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.home-container .home-container-item {
  padding: 1rem 0.25rem;
}
.item-text {
  width: 115px;
}
.item-text img {
  border-radius: 35px;
  width: 100%;
  user-select: none;
  cursor: pointer;
  transition: all .4s cubic-bezier(.24,-0.06,.76,1.06);
}

.item-text img:hover {
    filter: opacity(80%) blur(.5px) grayscale(1);
}

.home-container-item > .item-text > p {
  font-size: 10pt;
  font-weight: 600;
  user-select: none;
  cursor: pointer;
}
.home-container-item > .item-text > .item-price {
  background-color: var(--secondary);
  border-radius: 50px;
  width: max-content;
  padding: 8px 16px;
  margin: 10px auto 0 auto;
  color: var(--primary);
  font-weight: bold;
  font-size: 14pt;
  user-select: none;
  cursor: pointer;
}
