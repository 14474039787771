.header.list {
    max-width: 400px;
    width: 100%;
}
.list .list-items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--text-color);
    width: 400px;
    min-height: 100px;
    margin: auto;
    border-radius: 50px;
}

.list .list-items p {
    background-color: var(--primary);
    padding: 1rem 2rem;
    border-radius: 50px;
    color: var(--secondary);
    margin: 10px auto;
    text-align: center;
}

.list .list-items p:first-child {
    margin-top: 20px;
}
.list .list-items p:last-child {
    margin-bottom: 20px;
}