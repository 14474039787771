.user-container .user-container-item .item-text {
    cursor: pointer;
}
.user .card {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.user .card > * {
    padding: 5px;
}
.user .card img {
    width: 75px;
    height: 75px;
    border-radius: 50px;
    /* margin-top: 4.125em; */
}
.user .card .user-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -3rem;
}
.user .card .user-name p:first-of-type {
    color: var(--text-color);
    font-weight: 500;
    font-size: 18pt; 
}
p.user-edit-name {
    background-color: var(--secondary);
    padding: .5rem 1rem;
    color: var(--primary);
    border-radius: 50px;
    cursor: pointer;
}