.search-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 400px;
    margin: 0 auto;
    justify-content: space-evenly;
}
.search-container .home-container-item {
    display: flex;
    margin-top: 8px;
    width: 100%;
    border-radius: 25px;
    background-color: var(--text-color);
}
.search-container .item-text {
    width: 100%;
    display: flex;
    align-items: center;
}
.search-container .item-text img {
    width: 115px;
}
.search-container > .home-container-item > .item-text > p:first-of-type {
    padding: 0 .5rem;
}
.search-container > .home-container-item > .item-text > .item-price {
    text-align: center;
    min-width: 80px;
    margin: 0 10px;
}