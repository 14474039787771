.bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary);
    margin: 1.75rem auto 2rem auto;
    min-width: 300px;
    width: 80%;
    color: var(--primary);
    padding: 0 1em;
    border-radius: 50px;
    box-shadow: 0px .45em 0px var(--primary);
    transition: all .4s cubic-bezier(.24,-0.06,.76,1.06);
}
.bg:hover,
.bg:focus-within,
.bg input:-webkit-autofill,
.bg input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus     {
    background-color: var(--primary);
    -webkit-text-fill-color: var(--secondary);
    color: var(--secondary);
    cursor: pointer;
    box-shadow: 0px 0em 0px var(--primary);
}
.bg > input {
    border: none;
    outline: none;
    width: 18ch;
    font-size: 16pt;
    padding: 16px 0;
    text-indent: .5em;
    user-select: text;
    color: var(--primary);
    background-color: transparent;
}
.bg > input[type="button"] {
    cursor: pointer;
}
.bg > input::placeholder {
    color: var(--primary);
    text-align: center;
    margin-right: 2ch;
}
.bg:hover input::placeholder {
    color: var(--secondary);
}
.bg:hover > input,
input:focus,
input:focus input::placeholder {
    color: var(--secondary);
}
.bg > label > svg {
    margin: 4px auto 0;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}
.bg > p {
    width: 100%;
    text-align: center;
    user-select: none;
}

.bg-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.75rem auto 2rem auto;
    min-width: 300px;
    width: 80%;
    padding: 0 1em;
    border-radius: 50px;
    box-shadow: 0px .45em 0px var(--secondary);
    color: var(--secondary);
    background-color: var(--primary);
    transition: all .4s cubic-bezier(.24,-0.06,.76,1.06);
}
.bg-secondary:hover,
.bg-secondary:focus-within,
.bg-secondary input:-webkit-autofill,
.bg-secondary input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background-color: var(--secondary);
    -webkit-text-fill-color: var(--primary);
    color: var(--primary);
    cursor: pointer;
    box-shadow: 0px 0em 0px var(--secondary);
}
.bg-secondary > input {
    border: none;
    outline: none;
    width: 18ch;
    font-size: 16pt;
    padding: 16px 0;
    text-indent: .5em;
    color: var(--secondary);
    background-color: transparent;
}
.bg-secondary > input[type="button"] {
    cursor: pointer;
}
.bg-secondary > input::placeholder {
    color: var(--primary);
    text-align: center;
    margin-right: 2ch;
}
.bg-secondary:hover input::placeholder {
    color: var(--secondary);
}
.bg-secondary:hover > input,
input:focus,
input:focus input::placeholder {
    color: var(--secondary);
}
.bg-secondary > label > svg {
    margin: 4px auto 0;
    height: 2rem;
    width: 2rem;
}
.bg-secondary > p {
    width: 100%;
    text-align: center;
    user-select: none;
}
