.product-interface{
    width: 400px;
    height: 100%;
    background-color: var(--primary);
    margin: 1rem 0;
    border-radius: 50px;
}
.product-interface .back {
    padding: 1rem 1rem 0 1rem;
    font-size: 36px;
}
.product-interface .back svg {
    color: var(--primary);
    background-color: var(--secondary);
    padding: .5rem .75rem;
    border-radius: 25px; 
    box-shadow: 0px .25em 0px var(--terciary);
    transition: all .4s cubic-bezier(.24,-0.06,.76,1.06);
}

.product-interface .back svg:hover {
    background-color: var(--terciary);
    -webkit-text-fill-color: var(--secondary);
    color: var(--secondary);
    cursor: pointer;
    box-shadow: 0px 0em 0px var(--secondary);
}

.product-interface img {
    width: 125px;
    height: 125px;
    border-radius: 25px;
}
.product-interface .market-img {
    width: 75px;
    height: 75px;
    border-radius: 50px;
}
.product-interface .product-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}
.product-header > * {
    padding: 5px; 
}
.product-header > p {
    color: var(--text-color);
    font-weight: 600;
}
.market-price {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.market-price p {
    color: var(--primary);
    padding: .5rem 1rem;
    margin-top: 10px;
    background-color: var(--secondary); 
    border-radius: 50px;
}
.prices {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 1rem;
}
.prices .price-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.prices p {
    color: var(--text-color);
}
.prices .price-item-market {
    background-color: var(--secondary);
    padding: .75rem .5rem;
    border-radius: 50px;
    color: var(--primary); 
    margin-bottom: 4px;
}