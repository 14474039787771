@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap);
:root {
  --primary: #7879f1;
  --secondary: #e5e5a8;
  --terciary: #b5a9ff;
  --text-color: #effffe;
}
html,
body {
  background-color: #b5a9ff;
  background-color: var(--terciary);
  /* height: 100%; */
}
* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
body {
  overflow: auto;
  /* overflow: hidden; */
}
a {
  text-decoration: none;
  margin: 4px;
}
.container {
  height: 100%;
  align-items: center;
}
.container-flex {
  display: flex;
  flex-direction: column;
}
.header #title {
  color: #effffe;
  color: var(--text-color);
  font-size: 28pt;
  text-align: center;
  margin: auto;
  padding: 2.5rem 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.image {
  text-align: center;
}
.image > img {
  max-height: 25vh;
  opacity: 0.5;
  transition: all 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8);
}
.image > img:hover {
  max-height: 27.5vh;
}

.card {
  display: block;
}
.card > p {
  font-size: 14pt;
  color: #effffe;
  color: var(--text-color);
  text-align: center;
}
.card > p > a > em {
  text-decoration: none;
  position: relative;
  color: #e5e5a8;
  color: var(--secondary);
  cursor: pointer;
  font-style: normal;
}
.card > p > a > em::before {
  content: "";
  position: absolute;
  bottom: -2px;
  right: 0;
  width: 0;
  height: 4px;
  border-radius: 5px;
  background-color: #7879f1;
  background-color: var(--primary);
  transition: width 1s cubic-bezier(0.25, 1, 0.5, 1);
}
.card > p > a > em:hover::before {
  left: 0;
  right: auto;
  width: 100%;
}

@media (min-width: 800px) {
  #root,
  .App,
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .header {
    overflow: hidden;
  }
  .container-flex {
    flex-direction: row;
    background-color: #effffe;
    background-color: var(--text-color);
    width: -webkit-max-content;
    width: max-content;
    border-radius: 50px;
    padding: 60px;
    overflow: hidden;
    margin: auto;
    align-items: center;
  }
  .container-flex span {
    height: 16rem;
    width: 6px;
    border-radius: 50px;
    background-color: #7879f1;
    background-color: var(--primary);
    margin: 0px 50px;
  }
  .card {
    min-width: 300px;
  }
  .card > p {
    color: #7879f1;
    color: var(--primary);
  }
  .header #title {
    color: #7879f1;
    color: var(--primary);
    padding: 0;
  }
  .usuarios {
    margin-top: -2.5em;
  }
}

.bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary);
    margin: 1.75rem auto 2rem auto;
    min-width: 300px;
    width: 80%;
    color: var(--primary);
    padding: 0 1em;
    border-radius: 50px;
    box-shadow: 0px .45em 0px var(--primary);
    transition: all .4s cubic-bezier(.24,-0.06,.76,1.06);
}
.bg:hover,
.bg:focus-within,
.bg input:-webkit-autofill,
.bg input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus     {
    background-color: var(--primary);
    -webkit-text-fill-color: var(--secondary);
    color: var(--secondary);
    cursor: pointer;
    box-shadow: 0px 0em 0px var(--primary);
}
.bg > input {
    border: none;
    outline: none;
    width: 18ch;
    font-size: 16pt;
    padding: 16px 0;
    text-indent: .5em;
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
    color: var(--primary);
    background-color: transparent;
}
.bg > input[type="button"] {
    cursor: pointer;
}
.bg > input::-webkit-input-placeholder {
    color: var(--primary);
    text-align: center;
    margin-right: 2ch;
}
.bg > input:-ms-input-placeholder {
    color: var(--primary);
    text-align: center;
    margin-right: 2ch;
}
.bg > input::placeholder {
    color: var(--primary);
    text-align: center;
    margin-right: 2ch;
}
.bg:hover input::-webkit-input-placeholder {
    color: var(--secondary);
}
.bg:hover input:-ms-input-placeholder {
    color: var(--secondary);
}
.bg:hover input::placeholder {
    color: var(--secondary);
}
input:focus input::-webkit-input-placeholder {
    color: var(--secondary);
}
input:focus input:-ms-input-placeholder {
    color: var(--secondary);
}
.bg:hover > input,
input:focus,
input:focus input::placeholder {
    color: var(--secondary);
}
.bg > label > svg {
    margin: 4px auto 0;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}
.bg > p {
    width: 100%;
    text-align: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.bg-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.75rem auto 2rem auto;
    min-width: 300px;
    width: 80%;
    padding: 0 1em;
    border-radius: 50px;
    box-shadow: 0px .45em 0px var(--secondary);
    color: var(--secondary);
    background-color: var(--primary);
    transition: all .4s cubic-bezier(.24,-0.06,.76,1.06);
}
.bg-secondary:hover,
.bg-secondary:focus-within,
.bg-secondary input:-webkit-autofill,
.bg-secondary input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background-color: var(--secondary);
    -webkit-text-fill-color: var(--primary);
    color: var(--primary);
    cursor: pointer;
    box-shadow: 0px 0em 0px var(--secondary);
}
.bg-secondary > input {
    border: none;
    outline: none;
    width: 18ch;
    font-size: 16pt;
    padding: 16px 0;
    text-indent: .5em;
    color: var(--secondary);
    background-color: transparent;
}
.bg-secondary > input[type="button"] {
    cursor: pointer;
}
.bg-secondary > input::-webkit-input-placeholder {
    color: var(--primary);
    text-align: center;
    margin-right: 2ch;
}
.bg-secondary > input:-ms-input-placeholder {
    color: var(--primary);
    text-align: center;
    margin-right: 2ch;
}
.bg-secondary > input::placeholder {
    color: var(--primary);
    text-align: center;
    margin-right: 2ch;
}
.bg-secondary:hover input::-webkit-input-placeholder {
    color: var(--secondary);
}
.bg-secondary:hover input:-ms-input-placeholder {
    color: var(--secondary);
}
.bg-secondary:hover input::placeholder {
    color: var(--secondary);
}
input:focus input::-webkit-input-placeholder {
    color: var(--secondary);
}
input:focus input:-ms-input-placeholder {
    color: var(--secondary);
}
.bg-secondary:hover > input,
input:focus,
input:focus input::placeholder {
    color: var(--secondary);
}
.bg-secondary > label > svg {
    margin: 4px auto 0;
    height: 2rem;
    width: 2rem;
}
.bg-secondary > p {
    width: 100%;
    text-align: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.popup {
    width: 100%;
    position: absolute;
    min-height: 100vh;
    height: 100%;
    z-index: 999;
}
.popup-card {
    background-color: var(--text-color);
    width: min(80%, 320px, 80%);
    margin: 1em auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3em 1.5em 1.5em;
    border-radius: 50px;
}
.popup-card > .bg {
    width: 50%;
    min-width: 8ch;
}
.popup-card > .bg > input {
    width: 8ch;
    text-indent: 0;
}
.popup-card > p {
    font-size: clamp(14pt, 4vw, 16pt);
    text-align: center;
    color: var(--primary); 
}

.container {
    height: 100vh;
}

.signin .header > #title {
    padding: 0;
}
.product-interface{
    width: 400px;
    height: 100%;
    background-color: var(--primary);
    margin: 1rem 0;
    border-radius: 50px;
}
.product-interface .back {
    padding: 1rem 1rem 0 1rem;
    font-size: 36px;
}
.product-interface .back svg {
    color: var(--primary);
    background-color: var(--secondary);
    padding: .5rem .75rem;
    border-radius: 25px; 
    box-shadow: 0px .25em 0px var(--terciary);
    transition: all .4s cubic-bezier(.24,-0.06,.76,1.06);
}

.product-interface .back svg:hover {
    background-color: var(--terciary);
    -webkit-text-fill-color: var(--secondary);
    color: var(--secondary);
    cursor: pointer;
    box-shadow: 0px 0em 0px var(--secondary);
}

.product-interface img {
    width: 125px;
    height: 125px;
    border-radius: 25px;
}
.product-interface .market-img {
    width: 75px;
    height: 75px;
    border-radius: 50px;
}
.product-interface .product-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}
.product-header > * {
    padding: 5px; 
}
.product-header > p {
    color: var(--text-color);
    font-weight: 600;
}
.market-price {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.market-price p {
    color: var(--primary);
    padding: .5rem 1rem;
    margin-top: 10px;
    background-color: var(--secondary); 
    border-radius: 50px;
}
.prices {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 1rem;
}
.prices .price-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.prices p {
    color: var(--text-color);
}
.prices .price-item-market {
    background-color: var(--secondary);
    padding: .75rem .5rem;
    border-radius: 50px;
    color: var(--primary); 
    margin-bottom: 4px;
}
.interface {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}

.navbar {
  position: fixed;
  display: flex;
  bottom: 0;
  height: 80px;
  width: 100%;
  z-index: 999;
  min-width: 385px;
    justify-content: center;
  /* background-color: var(--secondary); */
}

.movable-navbar {
  display: flex;
  position: fixed;
  width: 100%;
  min-width: 385px;
  height: 80px;
  background-color: var(--secondary);
  z-index: -1;
  justify-content: center;
  transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
}
.movable-navbar .prev-item {
  position: absolute;
  height: 80px;
  width: 80px;
  margin-right: 485px;
  background-color: var(--terciary);
}
.movable-navbar .next-item {
  position: absolute;
  height: 80px;
  width: 80px;
  margin-right: 165px;
  background-color: var(--terciary);
}
.movable-navbar .current-item {
  position: relative;
  height: 80px;
  width: 80px;
  margin-right: calc(325px);
  border-radius: 0 0 50% 50%;
  background-color: var(--terciary);
}
.movable-navbar .current-item::before {
  content: "";
  position: absolute;
  height: 80px;
  width: 80px;
  right: 80px;
  border-radius: 0 50% 0 0;
  background-color: var(--secondary);
}
.movable-navbar .current-item::after {
  content: "";
  position: absolute;
  height: 80px;
  width: 80px;
  left: 80px;
  border-radius: 50% 0 0 0;
  background-color: var(--secondary);
}

.navbar-items {
  display: flex;
  color: var(--primary);
  height: 100%;
  max-width: 400px;
  list-style-type: none;
  margin: auto;
  justify-content: space-around;
  align-items: center;
}

.navbar-item {
  display: flex;
  height: 100%;
  width: 80px;
  align-items: center;
  font-size: clamp(24pt, 4vw, 2.75rem);
  justify-content: center;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  cursor: pointer;
  /* background-color: var(--secondary);  */
  -webkit-tap-highlight-color: transparent;
  transition: all ease 0.2s;
}

.navbar-item > * {
  pointer-events: none;
  z-index: 4;
}

.navbar-item.active {
  position: relative;
  height: 100%;
  width: 80px;
  color: var(--secondary);
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
  font-size: clamp(28pt, 4vw, 3rem);
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s;
}
.navbar-item-circle {
  z-index: 2;
  background-color: var(--primary);
  width: 100px;
  height: 100px;
  color: var(--secondary);
  border-radius: 50%;
}

header {
  margin: 1em;
}
.title {
  margin: auto;
  width: -webkit-max-content;
  width: max-content;
  -webkit-transform: rotateZ(-8deg);
          transform: rotateZ(-8deg);
  color: var(--primary);
  margin-bottom: -5.5rem;
  font-size: 44pt;
}
.title > em {
  text-decoration: none;
  font-style: normal;
  color: var(--text-color);
  font-weight: 100;
}
header .card {
  padding: 3.2em;
  /* margin: 0 auto -80px auto; */
  margin: auto;
  height: 200px;
  background-image: url(/static/media/bg.cd41bf66.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 1;
}

header .card ul {
  list-style: none;
  text-decoration: none;
  margin: 0 auto;
  display: flex;
  width: 100%;
  max-width: 400px;
  justify-content: space-evenly;
  color: var(--text-color);
}
header .card .item {
  text-decoration: none;
}

.mercados {
  z-index: 99;
  margin-top: -6rem;
}
.mercados .bg {
  max-width: -webkit-max-content;
  max-width: max-content;
  padding: 0;
}
.mercados .bg input {
  padding: 8px;
}

.header.search,
.header.list {
  width: 400px;
  margin: 0 auto;
}

.header.list {
  display: flex;
}

.header.list > .bg.bg-secondary > input,
.header.list > .bg.bg-secondary {
  width: -webkit-max-content;
  width: max-content;
  min-width: 0;
}
header.user {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user-container,
.home-container  {
  background-color: var(--primary);
  margin-top: -2rem;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0px .45em 0px var(--primary);
  transition: all .4s cubic-bezier(.24,-0.06,.76,1.06);
}

.user-container .user-container-item,
.home-container .home-container-item {
  text-align: center;
  font-size: 14pt;
  font-weight: 200;
  color: var(--text-color); 
  padding: 2.25rem 0; 
}

.user-container .item-spacer {
  display: inline-block;
  margin: auto;
  width: 80%;
  height: 6px;
  border-radius: 5px;
  background-color: var(--secondary); 
}

.spacer {
  height: 100px;
  width: 100%;
}

@media (max-width: 385px) {
  .movable-navbar {
    min-width: 100%;
  }
  .navbar {
    min-width: auto;
  }
  .navbar-items {
    width: auto;
    min-width: 100%;
  }
  .navbar-item, .navbar-item.active {
      width: 100%;
  }
  .navbar-item-circle {
    z-index: 2;
    background-color: var(--primary);
    max-width: 80px;
    height: 80px;
    color: var(--secondary);
    border-radius: 50%;
  }
  .movable-navbar .prev-item {
    width: 70px;
    margin-right: 455px;
  }
  .movable-navbar .next-item {
    width: 70px;
    margin-right: 175px;
  }
  .movable-navbar .current-item {
    width: 70px;
    margin-right: calc(315px);
  }
  .movable-navbar .current-item::before {
    width: 70px;
    right: 70px;
  }
  .movable-navbar .current-item::after {
    width: 70px;
    left: 70px;
  }
}

header.home {
  display: flex;
  align-items: center;
}
header .card .mercado-1 {
  margin-top: 4.5rem;
}
header .card .mercado-2 {
  margin-top: 3.5rem;
}
header .card .mercado-3 {
  margin-top: 2.75rem;
}
header .card .mercado-4 {
  margin-top: 2rem;
}

.item-group > .item > img {
  width: 75px;
  height: 75px;
  border-radius: 50px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  transition: all .4s cubic-bezier(.24,-0.06,.76,1.06);
}

.item-group > .item > img:hover {
    -webkit-filter: opacity(80%) grayscale(1);
            filter: opacity(80%) grayscale(1);
}

.home-container {
  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.home-container .home-container-item {
  padding: 1rem 0.25rem;
}
.item-text {
  width: 115px;
}
.item-text img {
  border-radius: 35px;
  width: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  transition: all .4s cubic-bezier(.24,-0.06,.76,1.06);
}

.item-text img:hover {
    -webkit-filter: opacity(80%) blur(.5px) grayscale(1);
            filter: opacity(80%) blur(.5px) grayscale(1);
}

.home-container-item > .item-text > p {
  font-size: 10pt;
  font-weight: 600;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.home-container-item > .item-text > .item-price {
  background-color: var(--secondary);
  border-radius: 50px;
  width: -webkit-max-content;
  width: max-content;
  padding: 8px 16px;
  margin: 10px auto 0 auto;
  color: var(--primary);
  font-weight: bold;
  font-size: 14pt;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.search-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 400px;
    margin: 0 auto;
    justify-content: space-evenly;
}
.search-container .home-container-item {
    display: flex;
    margin-top: 8px;
    width: 100%;
    border-radius: 25px;
    background-color: var(--text-color);
}
.search-container .item-text {
    width: 100%;
    display: flex;
    align-items: center;
}
.search-container .item-text img {
    width: 115px;
}
.search-container > .home-container-item > .item-text > p:first-of-type {
    padding: 0 .5rem;
}
.search-container > .home-container-item > .item-text > .item-price {
    text-align: center;
    min-width: 80px;
    margin: 0 10px;
}
.header.list {
    max-width: 400px;
    width: 100%;
}
.list .list-items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--text-color);
    width: 400px;
    min-height: 100px;
    margin: auto;
    border-radius: 50px;
}

.list .list-items p {
    background-color: var(--primary);
    padding: 1rem 2rem;
    border-radius: 50px;
    color: var(--secondary);
    margin: 10px auto;
    text-align: center;
}

.list .list-items p:first-child {
    margin-top: 20px;
}
.list .list-items p:last-child {
    margin-bottom: 20px;
}
.user-container .user-container-item .item-text {
    cursor: pointer;
}
.user .card {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.user .card > * {
    padding: 5px;
}
.user .card img {
    width: 75px;
    height: 75px;
    border-radius: 50px;
    /* margin-top: 4.125em; */
}
.user .card .user-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -3rem;
}
.user .card .user-name p:first-of-type {
    color: var(--text-color);
    font-weight: 500;
    font-size: 18pt; 
}
p.user-edit-name {
    background-color: var(--secondary);
    padding: .5rem 1rem;
    color: var(--primary);
    border-radius: 50px;
    cursor: pointer;
}
.header.add {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    margin: auto;
}
.container.form {
    width: 400px;
    margin: 20px auto 0 auto;
    background-color: var(--text-color);
    height: -webkit-max-content;
    height: max-content;
    border-radius: 50px;
}
.container.form > * {
    /* padding: 1rem; */
}
.container.form .back {
    padding-top: 1rem;
}

.container.form .back svg {
    color: var(--primary);
    background-color: var(--secondary);
    font-size: 18pt;
    padding: .5rem .75rem;
    border-radius: 25px; 
    box-shadow: 0px .25em 0px var(--terciary);
    transition: all .4s cubic-bezier(.24,-0.06,.76,1.06);
}

.container.form  svg:hover {
    background-color: var(--terciary);
    -webkit-text-fill-color: var(--secondary);
    color: var(--secondary);
    cursor: pointer;
    box-shadow: 0px 0em 0px var(--secondary);
}
.container.form .item-text{
    margin: auto;
}
