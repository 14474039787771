.container.form {
    width: 400px;
    margin: 20px auto 0 auto;
    background-color: var(--text-color);
    height: max-content;
    border-radius: 50px;
}
.container.form > * {
    /* padding: 1rem; */
}
.container.form .back {
    padding-top: 1rem;
}

.container.form .back svg {
    color: var(--primary);
    background-color: var(--secondary);
    font-size: 18pt;
    padding: .5rem .75rem;
    border-radius: 25px; 
    box-shadow: 0px .25em 0px var(--terciary);
    transition: all .4s cubic-bezier(.24,-0.06,.76,1.06);
}

.container.form  svg:hover {
    background-color: var(--terciary);
    -webkit-text-fill-color: var(--secondary);
    color: var(--secondary);
    cursor: pointer;
    box-shadow: 0px 0em 0px var(--secondary);
}
.container.form .item-text{
    margin: auto;
}